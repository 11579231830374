import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import './BusinessSlider.scss';
import Icon from '../../Icon';

function BusinessSlider({
  data,
  imageClass,
  settings,
  sliderContainer,
  sliderFocusContainer,
  OpenVideoModalToggle,
}) {
  return (
    <div className="business-slider-slick ml-[5%] md:ml-0 md:mr-[-19px]">
      <Slider {...settings}>
        {data.map((d) => (
          <div
            className="w-full md:w-1/3 px-[12px] md:p-[19px] !flex h-full flex-col"
            key={d.id}>
            <div
              className="w-full h-36 md:h-56 flex items-center justify-center flex-shrink-0 rounded-t relative"
              style={{
                background: d.background,
              }}>
              <img
                {...d.image}
                alt=""
                loading="lazy"
                className={`w-full relative max-w-[130px] md:max-w-[200px] customImgClass ${imageClass}`}
              />
              {d.isVideo && (
                <button
                  style={{
                    display: d.id === 2 ? 'flex' : 'none',
                  }}
                  type="button"
                  onClick={OpenVideoModalToggle}
                  className="usecase-play-icon absolute bottom-0 justify-center items-center text-white md:py-4 py-1 bg-[#081333] w-full bg-opacity-60 md:text-[14px] text-tiny">
                  Watch Video
                  <span className="justify-center items-center !flex ml-2 bg-white rounded-full md:p-2 z-10 p-[6px] ">
                    <Icon name="play" width="9px" className="pl-[1px]" />
                  </span>
                </button>
              )}
            </div>
            <div
              className={`${sliderContainer} text-2.5sm md:text-base p-[16px] md:p-[24px] custom-bg-section bg-white flex-1 rounded-b flex flex-col justify-between`}>
              <div>{d.text}</div>
              {d.focus?.length > 0 && (
                <div className="mt-6">
                  <div className="font-semibold md:font-normal text-tiny md:text-base">
                    Use case in focus:
                  </div>
                  <ul className="mt-[8px]">
                    {d.focus.map(({ text, id }) => (
                      <li
                        key={id}
                        className={`${sliderFocusContainer} px-[4px] md:px-[16px] py-[8px] md:py-[10px] flex items-center custom-bg-li bg-cf-light-grey rounded md:max-w-max mb-[8px] last:mb-01`}>
                        <span className="w-[20px] flex-shrink-0">
                          <img
                            src="/img/check.svg"
                            width="20"
                            height="21"
                            alt="check"
                            loading="lazy"
                          />
                        </span>
                        <div className="flex-1 pl-[8px] text-vtiny md:text-2.5sm text-cf-hero font-semibold">
                          {text}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

BusinessSlider.propTypes = {
  imageClass: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      image: PropTypes.shape({}),
      background: PropTypes.string,
      focus: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        }),
      ),
    }),
  ),
  settings: PropTypes.shape({}),
  sliderContainer: PropTypes.string,
  sliderFocusContainer: PropTypes.string,
  OpenVideoModalToggle: PropTypes.func,
};

BusinessSlider.defaultProps = {
  data: [],
  imageClass: '',
  settings: {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          // centerMode: false,
          slidesToShow: 1.3,
          infinite: false,
          arrows: true,
          dots: true,
        },
      },
    ],
  },
  sliderContainer: '',
  sliderFocusContainer: '',
  OpenVideoModalToggle: null,
};

export default BusinessSlider;
