import React, { useRef } from 'react';
import CheckList from '../components/CheckList/CheckList';
import FAQ from '../components/FAQ';
import GetStarted from '../components/GetStarted';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Tabs from '../components/Tabs/Tabs';
import OtherProducts from '../components/OtherProducts';
import BusinessSlider from '../components/Sliders/BusinessSlider/BusinessSlider';
import {
  faqData,
  lowestPricingList,
  getPaidFast,
  businessPaymentLinks,
  generatePaymentLink,
  // variousIndustriesUsecases,
  businessCards,
  acceptPaymentsAnywhere,
  customPricingList,
  otherCashfreeProducts,
  allLogoData,
  supportedPaymentMethods,
} from '../content/payment-links';
import '../styles/payment-links.scss';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import LogoMarquee from '../components/LogoMarquee/LogoMarquee';

function PaymentLink(rest) {
  const container = useRef();
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=payment%20links&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Payment Links - Generate & Send Online Payment Links Instantly"
        description="Cashfree Payment Links lets you generate instant payment links and share with your customers via SMS, WhatsApp, and email. No payment gateway integration or coding needed."
        url="https://www.cashfree.com/payment-links/"
        keywords={[
          'payment link',
          'google pay link',
          'generate payment link',
          'payment link generator',
          'create upi payment link',
          'pay links',
          'how to create payment link',
          'create payment link',
          'google pay payment link',
          'upi payment link generator',
        ]}
      />
      <section className="pb-[36px] pt-[110px] relative md:pt-[150px] md:pb-[56px] bg-cf-dark text-white paymentLinks-hero overflow-hidden">
        <div className="container position-relative z-10">
          <div className="flex flex-wrap justify-start lg:justify-between lg:w-[120%]">
            <div className="w-full lg:w-[40%] pb-10 md:pb-14 lg:self-center">
              <div className="text-[13px] leading-[20px] font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                <h2 className="inline font-body">Payment Links</h2>
              </div>
              <div className="mb-3 md:mb-5 text-xl md:text-hero-large font-semibold max-w-[650px] font-heading">
                <h1 className="inline">Generate Payment Link</h1>s and accept
                online payments instantly
              </div>
              <div className="text-opacity-80 max-w-[585px] text-[16px] leading-[24px] md:text-2.5md mb-8 font-body">
                Sell online without a website.{' '}
                <h2 className="inline font-body">Share Payment Links</h2> with
                customer via SMS, email or Whatsapp and get paid instantly via
                120+ payment modes.
              </div>

              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start">
                <a
                  className="button button-green w-full  md:w-auto mb-4 md:mb-0 mr-0 md:mr-4"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_Hero">
                  Create Account <span className="chevron" />
                </a>
                <a
                  className="button button-outline no-shadow text-white border-cf-stroke w-full md:w-auto"
                  href="https://docs.cashfree.com/docs/payment-links-introduction">
                  Explore the docs <span className="chevron !mb-0" />
                </a>
              </div>
              <div className="relative overflow-hidden z-[1] mt-10 md:mt-16">
                <LogoMarquee
                  data={allLogoData}
                  imgUrl="/img/payment-links/sliding-logos"
                  // duration="50s"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 lg:pl-[36px] flex justify-center h-full items-center">
              <div className="hidden md:block">
                <TransparentVideo
                  containerClass="nbfc-hero-video lg:left-[-10%]"
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/payment-links/poster-mb.png"
                  width="1454"
                  height="994"
                  mp4Src="/img/payment-links/hero-video-mb.mp4"
                  webmSrc="/img/payment-links/hero-video-mb.webm"
                />
              </div>
              <div className="block md:hidden">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/payment-links/poster-mb.png"
                  width="1454"
                  height="994"
                  mp4Src="/img/payment-links/hero-video-mb.mp4"
                  webmSrc="/img/payment-links/hero-video-mb.webm"
                />
              </div>
            </div>
          </div>
          <div className="supported-payment-methods mt-10 ">
            <div className=" font-semibold text-center text-[16px] leading-[24px]  mb-3 md:mb-9 font-body">
              Payment methods supported
            </div>
            <div className="flex justify-center items-center hidden-mobile">
              {supportedPaymentMethods.map(
                ({ id, alt, src, width, height }) => (
                  <div key={id} className="mx-8">
                    <img
                      src={`/${src}`}
                      alt={alt}
                      width={width}
                      height={height}
                    />
                  </div>
                ),
              )}
            </div>
            <div className="relative overflow-hidden z-[1] visible-mobile">
              <LogoMarquee
                data={supportedPaymentMethods}
                imgUrl=""
                duration="15s"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-2 relative payments-link text-center overflow-hidden bg-cf-light-grey">
        <div className="container">
          <div className="text-shs md:text-shl font-semibold mb-8 font-heading ">
            <h2 className="inline font-heading">
              How to generate a payment link
            </h2>
          </div>
          <div className="flex flex-col">
            <div className="leading-[24px] mb-0 md:mb-14 mt-7 md:mt-0 mx-auto w-full md:w-auto order-1 md:order-none ">
              <a
                className="button button-green  wide w-full md:max-w-max"
                href="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_HowToGeneratePaymentLink">
                Create Account <span className="chevron" />
              </a>
            </div>
            <div className="overflow-x-auto md:overflow-x-hidden">
              <div className="flex flex-nowrap mx-[-19px] w-[250%] md:w-auto">
                {generatePaymentLink.map(({ id, img, heading, desc }) => (
                  <div
                    key={id}
                    className="px-[19px] w-1/3 text-center payments-card">
                    <div className="bg-[#ffffff14] h-full rounded border border-[#ffffff23] flex flex-col justify-start relative">
                      <div className="lineRight" />
                      <div className="relative">
                        <img src={img} alt={heading} />
                      </div>
                      <div className="leading-[24px] mt-4 md:mt-[24px] mb-2 md:mb-[12px] text-[16px] font-semibold md:max-w-[291px] mx-auto ">
                        {heading}
                      </div>
                      <div className="leading-[21px] md:leading-[24px] text-[14px] md:max-w-[300px] mx-auto">
                        {desc}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-[48px] md:pt-[128px] md:pb-[109px] relative overflow-hidden  ">
        <div className="container">
          <div className="max-w-max md:max-w-[270px] mx-auto">
            <p className="text-sm font-semibold text-cf-primary pl-5 mb-[8px] md:mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
              Use cases for various industries
            </p>
          </div>
          <div className="text-shs md:text-shl font-semibold mb-8 md:mb-[41px] font-heading text-center">
            Do more with
            <br className="visible-mobile" />{' '}
            <h2 className="inline">Payment Links</h2>
          </div>
          <div className="flex pb-[48px] md:pb-0 payment-link-business-slider">
            <div className="w-full ">
              <BusinessSlider
                data={businessCards}
                settings={{
                  dots: true,
                  arrows: false,
                  infinite: false,
                  speed: 500,
                  slidesToShow: 3,
                  responsive: [
                    {
                      breakpoint: 768,
                      settings: {
                        // centerMode: false,
                        slidesToShow: 1.3,
                        infinite: false,
                        arrows: true,
                        dots: true,
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-cf-light-grey pt-12 md:pt-24 pb-10 md:pb-14 overflow-hidden">
        <div className="container">
          <div className="flex flex-col">
            <span className="block font-heading text-lg md:text-shl font-semibold mb-3 md:mb-8 max-w-[610px]">
              Accept payments anywhere <br className="hidden-mobile" />
              Use any channel, as your checkout
            </span>

            <div className="mt-[15px] md:mt-0 md:mb-[64px] order-1 md:order-none">
              <a
                className="button button-green w-full md:w-auto"
                href="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_AcceptPaymentsAnywhere">
                Create Account <span className="chevron" />
              </a>
            </div>
            <div className=" flex flex-wrap mx-[-7px] md:mx-[-20px]">
              {acceptPaymentsAnywhere.map(
                ({ id, description, heading, icon }) => (
                  <div
                    key={id}
                    className="w-full md:w-4/12 px-[7px] md:px-[20px] mb-4 md:mb-10 ">
                    <div className="bg-cf-white p-[16px] h-full rounded md:min-h-[220px]">
                      <div className="w-[30px] md:w-[40px] h-[30px] md:h-[40px] mb-[16px]">
                        <img
                          className="max-w-[30px] md:max-w-[40px] max-h-[30px] md:max-h-[40px] "
                          src={icon}
                          alt=""
                        />
                      </div>
                      <div>
                        <h3 className="font-body font-semibold mb-[8px] text-[16px] leading-[24px]">
                          {heading}
                        </h3>
                        <div className="text-2sm md:text-[15px] md:leading-[24px] tracking-[-0.1px] payment-desc">
                          {description}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="p-section-3 bav-verification">
        <div className="container">
          <div className="text-shs md:text-shl font-semibold max-w-[826px]  mb-6 md:mb-8 font-heading">
            <h2 className="inline">Payment Links for every business</h2>!
          </div>
          <Tabs
            data={businessPaymentLinks}
            dropDown
            timerAutoPlay
            dropDownFontSize="text-base"
          />
        </div>
      </section>

      <section className="payment-links-fastestway bg-cf-dark relative pt-[48px] md:pt-[140px] text-white overflow-hidden ">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="img-wrapper w-full md:w-1/2 order-1 md:order-none relative flex flex-col justify-end">
              <img src="/img/payment-links/fastest-way.png" alt="fastest-way" />
            </div>
            <div className="w-full md:w-1/2 md:pl-24 md:pb-8">
              <div className="text-shs md:text-shl font-semibold mb-[20px] font-heading max-w-[850px] mx-auto">
                Fastest way to get paid
              </div>
              <div className="max-w-[500px] md:max-w-[510px] m-0 ">
                {getPaidFast.map(({ id, text }) => (
                  <div key={id} className="flex mb-[16px]">
                    <span className="mt-0.5 w-5 h-5 flex justify-center items-center rounded-full mr-[10px] bg-[#ffffff40]">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <div className="flex-1 m-0 text-cf-cold-purple text-2.5sm md:text-md">
                      {text}
                    </div>
                  </div>
                ))}
              </div>
              <a
                className="button button-green w-full mt-[20px] md:mt-[16px] md:w-auto mb-[48px] md:mb-0 mr-0 md:mr-4"
                href="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_FasterWayToGetPaid">
                Create Account <span className="chevron" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-2 relative bg-cf-light-grey">
        <div className="container">
          <div className="flex flex-col md:flex-row flex-wrap md:-mx-3.5 md:items-center">
            <div className="w-full md:w-2/3  md:px-3.5">
              <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                Lowest Pricing
              </p>
              <p className=" text-4md md:text-shl font-semibold text-cf-hero mb-3 md:mb-5 font-heading max-w-[610px]">
                Transparent and friendly pricing
              </p>
              <div className="text-2.5sm md:text-md mb-6 md:mb-[32px] max-w-[610px] text-cf-hero font-normal">
                Get started today and get Zero Charges* for Link Creation and
                Reminders! Standard Payment Gateway charges apply.
              </div>
              <div className="flex flex-wrap ">
                <div className="max-w-full md:max-w-[362px] md:mr-10 mb-4 md:mb-4">
                  <CheckList
                    data={lowestPricingList.slice(0, 2)}
                    className="mb-0"
                  />
                </div>
                <div className="max-w-full pl-0 md:mt-0">
                  <CheckList
                    data={lowestPricingList.slice(2)}
                    className="mb-0"
                  />
                </div>
              </div>
              <a
                className="button button-green w-full mt-8 md:w-auto mb-12 md:mb-0 mr-0 md:mr-4"
                href="https://www.cashfree.com/payment-gateway-charges/">
                Check Pricing <span className="chevron" />
              </a>
            </div>

            <div className="w-full md:w-1/3 px-0 md:px-[20px] ">
              <div className="bg-cf-dark px-[16px] md:px-[27px] py-[24px] md:pt-[30px] md:pb-[24px] h-full rounded flex flex-col justify-center relative dark-card-secondary overflow-hidden border-t-4 border-cf-primary">
                <div className="text-cf-white">
                  <p className="text-[12px] md:text-[13px] leading-[20px] text-cf-cold-purple font-semibold uppercase mb-[8px] md:mb-[10px]">
                    Enterprises
                  </p>
                  <h3 className="font-body font-bold md:font-semibold text-[20px] leading-8">
                    Custom pricing
                    <br /> designed for enterprises
                  </h3>
                  <div className="checklist-wrapper mt-[32px] md:mt-[28px] mb-[42px] md:mb-[35px]">
                    {customPricingList.map(({ id, text }) => (
                      <div key={id} className="flex mb-4">
                        <span className="mt-0.5 w-5 h-5 flex justify-center items-center rounded-full mr-[10px] bg-[#ffffff40]">
                          <Icon
                            name="tick"
                            width="9.21px"
                            height="6.29px"
                            fill="#fff"
                          />
                        </span>
                        <p className="flex-1 m-0 text-[14px] md:text-[15px] leading-[21px] md:leading-[24px] ">
                          {text}
                        </p>
                      </div>
                    ))}
                  </div>
                  <p className="text-tiny text-cf-cold-purple ">
                    Get in touch with our sales team to explore the right
                    products for your payment needs and get custom pricing.
                  </p>
                  <a
                    className="button button-outline w-full mt-[20px] md:mt-[35px] md:w-auto mb-4 md:mb-0 mr-0 flex items-center justify-center"
                    href="https://www.cashfree.com/contact-sales?source-action=payment%20links&action=Contact%20Sales&button-id=ContactSales_Pricing">
                    Contact Sales &nbsp;
                    <span className="chevron" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-12 md:pt-[128px] md:pb-8 ">
        <div className="container ">
          <div className=" py-[40px] md:py-[54px] px-[16px] md:px-[80px] bg-cf-primary rounded relative overflow-hidden custom-payment-form">
            <div className="flex flex-wrap items-center justify-between relative">
              <div className=" mb-6 md:mb-0 ">
                <div className="text-white text-3md md:text-[20px] md:leading-8 font-semibold  mb-[12px]">
                  Create Custom Payment Forms and get paid instantly!
                </div>
                <div className="w-full md:max-w-[624px] text-2.5sm md:text-md text-cf-light-cold-purple">
                  Sign up for in-built checkout payment pages and get paid right
                  away
                </div>
              </div>
              <div className="w-full md:w-auto">
                <a
                  className="button button-green w-full relative z-10 md:w-auto mb-4 md:mb-0 mr-0 md:mr-4"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_CreateCustomPaymentForms">
                  Create Account <span className="chevron" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container general-faqs">
          <FAQ
            open="0"
            data={faqData}
            supportLinkHref="https://www.cashfree.com/help/hc"
          />
        </div>
      </section>
      <section className="payment-other-products">
        <OtherProducts
          heading="Explore other products"
          data={otherCashfreeProducts}
        />
      </section>
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=payment%20links&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default PaymentLink;
