import React from 'react';

import TabPanel from '../components/Tabs/TabPanel';

export const sliderSettings = {
  dots: false,
  arrows: false,
  swipe: false,
  pauseOnHover: false,
  draggable: false,
  centerMode: false,
  speed: 2000,
  autoplay: false,
  autoplaySpeed: 2000,
  rtl: false,
  cssEase: 'linear',
  infinite: false,
  slidesToShow: 6,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        variableWidth: true,
      },
    },
  ],
};

export const lowestPricingList = [
  {
    id: 0,
    text: <>No setup, maintenance or any other hidden fees</>,
  },
  {
    id: 1,
    text: <>Pay only for actual transactions</>,
  },
  {
    id: 2,
    text: <>Real-time transaction fees reporting</>,
  },
  {
    id: 3,
    text: <>Paperless and same day onboarding</>,
  },
];

export const faqData = [
  {
    id: 0,
    q: 'What is Cashfree Payment Links and why should I use them?',
    a: (
      <>
        <p>
          Payment Links is the easiest way to receive payments from your
          customers for any goods or services, without taking them to any app or
          website. It doesn&apos;t even require any integration with a Payment
          Gateway.
        </p>
        <p>
          The Payment Link can be shared with the customer via different
          channels like WhatsApp, SMS, email etc., and upon clicking the link,
          your customers can pay using all available payment methods. Cashfree
          Payments will notify you when payment(s) are made using the link.
        </p>
      </>
    ),
  },
  {
    id: 1,
    q: 'How do I start using Payment Links with Cashfree?',
    a: (
      <>
        <p>
          Simply sign up for Payment Links account, and Cashfree Payment Gateway
          will be automatically activated for your account.
        </p>
        <p>
          The Payment Links feature will be available inside the Payment Gateway
          product.
        </p>
        <p>Generate Payment Link in 3 easy steps:</p>
        <ul className="list-decimal">
          <li>
            Create a Payment Link via Payment Links dashboard or APIs. Customize
            the link to match with your brand by adding your brand color and
            logo.
          </li>
          <li>
            Add customer details and paste or embed link anywhere. Share it
            across with customers.
          </li>
          <li>
            Accept payments instantly. Get notified in real time about a
            successful payment.
          </li>
        </ul>
        <p>
          All payments collected via the payment links are automatically updated
          to in the reports and transactions module in Cashfree Payment Gateway.
          Know more in our{' '}
          <a
            href="https://cashfree.gitbook.io/payment-links/create-payment-link"
            className="link text-cf-green hover:text-cf-green">
            documentation.
          </a>
        </p>
      </>
    ),
  },
  {
    id: 2,
    q: 'What information do I need to provide to create Payment Links?',
    a: (
      <>
        <p>
          To quickly request payment from your customer via Payment Link, you
          simply need to provide the customer phone or email information, and
          the amount and purpose for the link, and the link can be shared with
          the customer. While creating a Payment Link, you can enable the ‘Send
          SMS and Email’ checkboxes, and Cashfree Payments will send the link to
          the customer on the provided contact information.
        </p>
        <p>
          In addition to this, you can set the expiry for the link, and enable
          partial payments, if you want to enable the customer to make payment
          in parts.
        </p>
      </>
    ),
  },
  {
    id: 3,
    q: 'Can I generate Payment Links in bulk?',
    a: (
      <>
        <p>
          Yes, you can send Payment Links in bulk with our Batch feature. You
          can upload a XLSX or CSV file containing the list of customers’
          contact details, amount and purpose for the links and send the
          requests in bulk. All this within a matter of minutes!
        </p>
      </>
    ),
  },
  {
    id: 4,
    q: 'Can my customers make multiple partial payments on Payment Links?',
    a: (
      <p>
        Yes, you can enable Partial Payments and provide the minimum amount you
        wish to collect for a Payment Link, and the customer can choose to make
        multiple payments, between the minimum amount and the link amount.
        Cashfree Payments will notify you of all partial payments done.
      </p>
    ),
  },
  {
    id: 5,
    q: 'Are international payments supported on Payment Link?',
    a: (
      <>
        <p>
          Yes, it is possible to enable international payments on Cashfree
          Payment Links -- talk to us and help us understand your business for
          quick activation. If you have a Paypal account,{' '}
          <a
            href="https://blog.cashfree.com/paypal-integration/"
            className="link text-cf-green hover:text-cf-green">
            connect Paypal to Cashfree
          </a>{' '}
          and start offering Paypal as a checkout option on the payment page
          instantly.
        </p>
      </>
    ),
  },
  {
    id: 6,
    q: 'What are Orders?',
    a: (
      <>
        <p>
          Each time your customer opens a Payment Link and attempts payment, a
          new Order is created against that Link. This gives you the information
          on how many times the customer has visited the Link before completely
          paying the full amount. You can follow up with such customers and try
          to improve your conversion rate.
        </p>
      </>
    ),
  },
];

const headingClass = 'mb-2 md:mb-[12px]';
const contentBodyMarginClass = 'mb-1 md:mb-8 max-w-[500px]';
const imageClassName = 'w-full md:pr-[18px]';
const alignStart = true;
const contentClassName = 'md:pl-[19px] mt-0 lg:pt-[60px] md:pt-[30px]';
const mobileVisibleHeading = false;

export const businessPaymentLinks = [
  {
    key: 0,
    heading: 'Sell Products',
    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass}
          img={{
            src: '/img/payment-links/sell-products.png',
            alt: 'sell products',
            width: '610',
            height: '464',
          }}
          heading="Sell Products"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                D2C brands and businesses that use social media platforms like
                Instagram, WhatsApp etc. for selling, don’t need a website to
                get paid anymore. Personalize the checkout page, add your brand
                logo, choose colors and style that reflect your brand, and get
                paid instantly.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_SellProduct">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Bill Payments',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass}
          img={{
            src: '/img/payment-links/bill-payments.png',
            alt: 'bill payments',
            width: '610',
            height: '464',
          }}
          heading="Bill Payments"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Send reminders and collect payments for utility bills and
                similar services such as telephone, gas, electricity bills,
                insurance premium and other services using Payment Links.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_BillPayments">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'Loan Repayments',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass}
          img={{
            src: '/img/payment-links/loan-repayments.png',
            alt: 'Loan Repayments',
            width: '610',
            height: '464',
          }}
          heading="Loan Repayments"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Lending companies, NBFCs and agents can use Payment Links to
                collect loan installments, SIP installments, credit card
                payments.
              </div>
            </>
          }
          footer={
            <>
              <a
                className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
                href="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_LoanRepayments">
                Create Account <span className="chevron" />
              </a>
            </>
          }
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: 'Fee Payments',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass}
          img={{
            src: '/img/payment-links/fee-payments.png',
            alt: 'Fee Payments',
            width: '610',
            height: '464',
          }}
          heading="Fee Payments"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Tutorials, K12 schools, coaching and education institutes can
                collect fees in bulk by sending links and reminders to students.
                Online courses, Olympiads providers and educators can request,
                send reminders, and collect course and test fees.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_FeePayments">
              Create Account <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 4,
    heading: 'Travel Booking',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass}
          img={{
            src: '/img/payment-links/travel-booking.png',
            alt: 'Travel Booking',
            width: '610',
            height: '464',
          }}
          heading="Travel Booking"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Provide Book Now Pay Later payment strategy to customers and
                collect amount at time of service completion.
              </div>
            </>
          }
          footer={
            <>
              <a
                className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
                href="https://merchant.cashfree.com/merchants/signup?source-action=payment%20links&action=Sign%20Up&button-id=StartNow_TravelBooking">
                Create Account <span className="chevron" />
              </a>
            </>
          }
        />
      </div>
    ),
  },
  {
    key: 5,
    heading: 'Invoice and B2B payments',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingType="div"
          headingClass={headingClass}
          img={{
            src: '/img/payment-links/b2b-payments.png',
            alt: 'Invoice and B2B payments',
            width: '610',
            height: '464',
          }}
          heading="Invoice and B2B payments"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Integrate Payment Links with your invoicing tool or ERPs to
                automate collections.
              </div>
            </>
          }
          footer={
            <>
              <div className="bg-cf-primary px-4 pt-[33px] pb-3 text-2sm md:text-[14px] md:leading-[24px] text-white rounded relative mt-4 border border-cf-stroke max-w-[362px] ml-[4px] md:ml-0">
                <div className="ribbon-purple">BONUS</div>
                <span className="block mt-1">
                  Integrate with your accounting or invoicing ERP and offer to
                  your customers.
                </span>
              </div>
              <a
                className="button button-green mt-8  mr-4 wide w-full md:w-auto"
                href="https://www.cashfree.com/partner-program/">
                Partner with us <span className="chevron" />
              </a>
            </>
          }
        />
      </div>
    ),
  },
];

export const getPaidFast = [
  {
    id: 0,
    text: (
      <>
        <span className="text-white font-medium">Easy Integration:</span> Create
        orders, and <h2 className="inline font-body">generate payment link</h2>s
        in bulk by uploading a simple .csv or .xlsx file. Save time and reduce
        errors. <h2 className="inline font-body">Create Payment Links</h2> with
        your systems using APIs.
      </>
    ),
  },
  {
    id: 1,
    text: (
      <>
        <span className="text-white font-medium">Easier reconciliation:</span>{' '}
        Get real-time reports on the transactions processed through{' '}
        <h2 className="inline font-body">payment links</h2> and help your
        finance team manage the books better.
      </>
    ),
  },
];

export const generatePaymentLink = [
  {
    id: 1,
    img: '/img/payment-links/generate-paymentlink1.png',
    heading: <h2 className="inline font-body">Create a Payment Link</h2>,
    desc: (
      <>
        Via Payment Links dashboard or APIs. Customize the link to match with
        your brand by adding your brand color and&nbsp;logo.
      </>
    ),
  },
  {
    id: 2,
    img: '/img/payment-links/generate-paymentlink2.png',
    heading: <h2 className="inline font-body">Share the Payment Link</h2>,
    desc:
      'Add customer details and paste or embed link anywhere. Share it across with customers.',
  },
  {
    id: 3,
    img: '/img/payment-links/generate-paymentlink3.png',
    heading: 'Get Paid Instantly',
    desc: <>Get notified in real time about a successful&nbsp;payment.</>,
  },
];

export const variousIndustriesUsecases = [
  {
    id: 1,
    img: '/img/payment-links/orchids.jpg',
    name: 'orchids',
    desc:
      'Send automated SMS to your customers to remind them about outstanding payments. Set multiple automated reminders to get paid on time and boost your revenue.',
    list: 'Reminders on auto-pilot',
  },
  {
    id: 2,
    img: '/img/payment-links/apml.jpg',
    name: 'apml',
    desc:
      'Let your customers initiate partial payments in case of service fulfillment and get the remaining payment on completion of the service.',
    list: 'Accept Partial Payments',
  },
  {
    id: 3,
    img: '/img/payment-links/tata-cliq.jpg',
    name: 'tata-cliq',
    desc:
      'Generate Payment Links to target customers with abandoned carts for quick payment collection thereby improving conversion.',
    list: 'Cart abandonment',
  },
];

export const acceptPaymentsAnywhere = [
  {
    id: 0,
    icon: '/img/payment-links/chat-sms.svg',
    heading: 'Chat & SMS',
    description: (
      <>
        <div>Share payment links via SMS and sell on the go.</div>
      </>
    ),
  },
  {
    id: 1,
    icon: '/img/payment-links/email.svg',
    heading: 'Email',
    description: (
      <>
        <div>Reduce cart abandonment and add links on the email.</div>
      </>
    ),
  },
  {
    id: 2,
    icon: '/img/payment-links/social-media.svg',
    heading: 'Social Media',
    description: (
      <>
        <div>Turn your social media feed into a shopping site.</div>
      </>
    ),
  },
  {
    id: 4,
    icon: '/img/payment-links/chatbots.svg',
    heading: 'Chatbots',
    description: (
      <>
        <div>
          Enable online payments as part of conversational flow using links.
        </div>
      </>
    ),
  },
  {
    id: 5,
    icon: '/img/payment-links/digital-invoice.svg',
    heading: 'Digital Invoice',
    description: (
      <>
        <div>Add links to your invoices and get paid instantly.</div>
      </>
    ),
  },
  {
    id: 3,
    icon: '/img/payment-links/doorstep-delivery.svg',
    heading: 'Door step delivery',
    description: (
      <>
        <div>No cash, no worries. Use links to get paid instantly.</div>
        <div className="bg-cf-primary tracking-normal px-3md pt-[33px] pb-[8px] text-[14px] leading-[24px] text-white rounded relative mt-[10px] border border-cf-stroke max-w-[364px] ml-[4px] md:ml-0">
          <div className="ribbon-purple">Introducing softPOS</div>
          <span className="block text-[13px] leading-[24px]">
            Turn android phone into a POS machine.{' '}
            <a
              href="https://www.cashfree.com/soft-pos/"
              className="button button-unstyled btn p-0 left-[83px] !right-auto leading-[24px] underline hover:underline font-normal text-[13px] min-w-max">
              Learn More
            </a>
          </span>
        </div>
      </>
    ),
  },
];

export const customPricingList = [
  {
    id: 0,
    text: 'Early access to new features',
  },
  {
    id: 1,
    text: 'Dedicated account manager',
  },
  {
    id: 2,
    text: 'Discount pricing',
  },
  {
    id: 3,
    text: 'Support over WhatsApp in addition to other channels',
  },
];

export const otherCashfreeProducts = [
  {
    id: 0,
    title: 'Payment Gateway',
    body: 'Accept domestic and international payments for your website or app.',
    url: 'https://www.cashfree.com/payment-gateway-india/',
    iconname: '/img/payment-links/payment-gateway.svg',
  },
  {
    id: 1,
    title: 'Payment Forms',
    body:
      'Create custom payment pages, display your product/service & collect online payments via 120+ payment methods like UPI, net banking, etc',
    url: 'https://www.cashfree.com/payment-forms/',
    iconname: '/img/payment-links/payment-forms.svg',
  },
  {
    id: 2,
    title: 'Instant Settlements',
    body:
      'Get access to your payment gateway collections within 15 minutes of payment capture, avoid dependency on credit lines.',
    url: 'https://www.cashfree.com/instant-settlements/',
    iconname: '/img/payment-links/settlements.svg',
  },
  {
    id: 3,
    title: 'Buy Now Pay Later',
    body:
      'Provide flexible payment options with card and cardless EMI, Pay Later and increase customer conversions by 30%.',
    url: 'https://www.cashfree.com/buy-now-pay-later/',
    iconname: '/img/payment-links/buynow-paylater.svg',
  },
];

export const allLogoData = [
  {
    id: 0,
    src: 'tata-cliq.svg',
    alt: 'Tata Cliq',
  },
  {
    id: 1,
    src: 'cred.svg',
    alt: 'cred',
  },
  {
    id: 2,
    src: 'apml.svg',
    alt: 'APML',
  },
  {
    id: 3,
    src: 'nestery.svg',
    alt: 'The Nestery',
  },
  {
    id: 4,
    src: 'bombay-shirt-company.svg',
    alt: 'bombay-shirt-company',
  },
  {
    id: 5,
    src: 'khadi.svg',
    alt: 'khadi essentials',
  },
  {
    id: 6,
    src: 'wedmegood.svg',
    alt: 'wed me good',
  },
  {
    id: 7,
    src: 'wonderchef.svg',
    alt: 'wonderchef',
  },
  {
    id: 8,
    src: 'pinelabs.svg',
    alt: 'pinelabs',
  },
  {
    id: 9,
    src: 'bajaj-finserv.svg',
    alt: 'bajaj-finserv',
  },
  {
    id: 10,
    src: 'drivezy.svg',
    alt: 'drivezy',
  },
  {
    id: 11,
    src: 'raw.svg',
    alt: 'raw',
  },
];

export const supportedPaymentMethods = [
  {
    id: 0,
    src: 'img/payment-links/upi.svg',
    alt: 'upi',
    width: '74',
    height: '21',
    className: 'max-w-[74px]',
  },
  {
    id: 1,
    src: 'img/payment-links/gpay.png',
    alt: 'google pay',
    width: '125',
    height: '23',
    className: 'max-w-[125px]',
  },
  {
    id: 2,
    src: 'img/payment-links/amazon-pay.svg',
    alt: 'amazon-pay',
    width: '59',
    height: '49',
    className: 'max-w-[59px]',
  },
  {
    id: 3,
    src: 'img/payment-links/visa.png',
    alt: 'visa',
    width: '70',
    height: '22',
    className: 'max-w-[70px]',
  },
  {
    id: 4,
    src: 'img/payment-links/mastercard.svg',
    alt: 'mastercard',
    width: '125',
    height: '22',
    className: 'max-w-[125px]',
  },
  {
    id: 5,
    src: 'img/payment-links/paypal.svg',
    alt: 'paypal',
    width: '90',
    height: '22',
    className: 'max-w-[90px]',
  },
];

export const businessCards = [
  {
    id: 0,
    image: {
      src: '/img/payment-links/orchids.png',
      width: '152',
      height: '152',
      alt: 'orchids',
    },
    background: '#912224',
    text: (
      <>
        Send automated SMS to your customers to remind them about outstanding
        payments. Set multiple automated reminders to get paid on time and boost
        your revenue.
      </>
    ),
    focus: [
      {
        id: 0,
        text: 'Reminders on auto-pilot',
      },
    ],
  },
  {
    id: 1,
    image: {
      src: '/img/payment-links/apml.png',
      width: '103',
      height: '106',
      alt: 'apml',
    },
    background: '#CE0909',
    text: (
      <>
        Let your customers initiate partial payments in case of service
        fulfillment and get the remaining payment on completion of the service.
      </>
    ),
    focus: [
      {
        id: 0,
        text: 'Accept partial payments',
      },
    ],
  },
  {
    id: 2,
    image: {
      src: '/img/payment-links/tata-cliq.png',
      width: '160',
      height: '57',
      alt: 'tata-cliq',
    },
    background: '#AB1E40',
    text: (
      <>
        <h2 className="inline font-body">Generate Payment Links</h2> to target
        customers with abandoned carts for quick payment collection thereby
        improving conversion.
      </>
    ),
    focus: [
      {
        id: 0,
        text: 'Cart abandonment',
      },
    ],
  },
];
