import React from 'react';
import PropTypes from 'prop-types';
// import './LogoMarquee.scss';

const LogoMarquee = ({ data, imgUrl, itemClassName, duration }) => {
  return (
    <div className="flex whitespace-no-wrap overflow-x-hidden">
      <div className="relative flex">
        <ul
          className="flex animate-marquee min-w-full flex-shrink-0"
          style={{ animationDuration: duration }}>
          {data.map((d) => (
            <li className={itemClassName} key={d.id}>
              <img
                className={`w-full block flex-shrink-0 ${d.className ?? ''}`}
                src={`${imgUrl}/${d.src}`}
                alt={d.alt}
                width={d.width}
                height={d.height}
                loading="lazy"
              />
            </li>
          ))}
        </ul>
        <ul
          className="flex animate-marquee min-w-full flex-shrink-0"
          style={{ animationDuration: duration }}>
          {data.map((d) => (
            <li className={itemClassName} key={d.id}>
              <img
                className={`w-full block flex-shrink-0 ${d.className ?? ''}`}
                src={`${imgUrl}/${d.src}`}
                alt={d.alt}
                width={d.width}
                height={d.height}
                loading="lazy"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
LogoMarquee.defaultProps = {
  data: [],
  imgUrl: '',
  duration: '30s',
  itemClassName:
    'flex-shrink-0 flex items-center align-center h-[50px] mx-[26px] md:mx-[40px]',
};
LogoMarquee.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      className: PropTypes.string,
    }),
  ),
  imgUrl: PropTypes.string,
  itemClassName: PropTypes.string,
  duration: PropTypes.string,
};
export default LogoMarquee;
